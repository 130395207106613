import { useAtomValue } from "jotai/react";
import type { Atom } from "jotai/vanilla";
import { type ReactNode, useRef } from "react";
import { createPortal } from "react-dom";
import { shortTimeFormat } from "./shortTime.ts";

export interface TooltipData {
    targetElement: HTMLDivElement;
    x: number;
    y: number;

    isAbove: boolean;

    time: Date;
    value: number;
}

// const anchorTopRight = "translate(-100%, 0)";
// const anchorMiddleLeft = "translate(0, -50%)";
// const anchorBottomCenter = "translate(-50%, -100%)";
const anchorBottomLeft = "translate(0, -100%)";

export function PlotTooltip({
    atom,
    formatValue = (value) => value.toPrecision(3),
    className,
}: {
    atom: Atom<TooltipData | undefined>;
    formatValue?: (value: number) => ReactNode;
    className?: string;
}) {
    //#region Hooks
    const data = useAtomValue(atom);
    const targetRef = useRef<HTMLDivElement | null>(null);
    //#endregion

    if (!data) {
        return null;
    }
    const { targetElement, time, value, x, y, isAbove: _ } = data;
    targetRef.current = targetElement;

    // TODO: Use a proper time format
    const content = (
        <>
            <span className="font-size-small">{shortTimeFormat(time)}</span>
            <br />
            {formatValue(value)}
        </>
    );

    // TODO: The tooltip can be placed out of viewport bounds when hovering near the right edge of the viewport

    // Leave a bit of space between the tooltip and the cursor
    const margin = "4px";

    // XXX: react-aria's TooltipInner had a crash bug with state handling so reimplementing tooltips here
    return createPortal(
        <div
            style={{
                zIndex: 30,
                position: "absolute",
                pointerEvents: "none",
                top: `${y}px`,
                left: `${x}px`,
                overflow: "visible",
                whiteSpace: "nowrap",
            }}
        >
            <div
                style={{
                    // transform: isAbove ? anchorTopRight : anchorBottomCenter,

                    // Anchor the bottom left corner of the tooltip to the cursor (so the cursor icon would not cover the tooltip)
                    transform: `${anchorBottomLeft} translate(${margin}, -${margin})`,

                    position: "absolute",
                    border: "1px solid var(--color-grey-4)",
                    borderRadius: "var(--input-border-radius)",
                }}
                className={className}
            >
                {content}
            </div>
        </div>,
        targetElement,
    );
}

function distance(x1: number, y1: number, x2: number, y2: number) {
    return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
}

export function tooltipPlugin(setData: (data: TooltipData | undefined) => void): uPlot.Plugin {
    const MAX_HOVER_DISTANCE = 40;
    return {
        hooks: {
            // This only triggers when the cursor is inside the plot area (not eg. the padding), unfortunately
            setCursor(u) {
                const {
                    cursor: { idx, left, top },
                } = u;
                if (idx == null) {
                    setData(undefined);
                    return;
                }

                const xVal = u.data[0][idx];
                const value = u.data[1][idx];

                // Assumes the x-axis is a time axis
                const time = new Date(xVal);

                const targetElement = u.over;

                if (value == null) {
                    setData(undefined);
                    return;
                }

                const pointX = u.valToPos(xVal, "x");
                const pointY = u.valToPos(value, "y");
                const cursorX = left!;
                const cursorY = top!;

                const isAbove = pointY < cursorY;

                const distanceToCursor = distance(cursorX, cursorY, pointX, pointY);
                if (distanceToCursor > MAX_HOVER_DISTANCE) {
                    setData(undefined);
                    return;
                }

                // The tooltip is shown at the cursor position
                // (to make it not jump around as much when hovering over ragged lines)
                setData({ targetElement, x: cursorX, y: cursorY, isAbove, time, value });
            },
        },
    };
}
