import "uplot/dist/uPlot.min.css";

import { clamp } from "@warrenio/utils/clamp";
import { MINUTES } from "@warrenio/utils/timeUnits";
import { exhaustiveSwitchCheck } from "@warrenio/utils/unreachable";
import { useAtomValue, useSetAtom } from "jotai/react";
import { atomWithLazy } from "jotai/utils";
import { atom } from "jotai/vanilla";
import React, { Suspense, useEffect, useMemo, useRef, useState, type ComponentProps, type ReactNode } from "react";
import { mergeDeep } from "remeda";
import type uPlot from "uplot";
import { InfoTooltip } from "../../../components/WTooltip.tsx";
import { mcn } from "../../../utils/baseProps.ts";
import { useOnce } from "../../../utils/react/useOnce.ts";
import { useLayoutSizeObserver } from "../../../utils/useLayoutSizeObserver.ts";
import type { LocationSlug } from "../../location/query.ts";
import { useThemeVar } from "../../theme/useTheme.ts";
import type { MetricId } from "./metricIds.ts";
import { metricsAtom, type EventSummary, type State } from "./MetricsManager.ts";
import { PlotTooltip, tooltipPlugin, type TooltipData } from "./PlotTooltip.tsx";
import { shortTimeFormat } from "./shortTime.ts";

const uplotImportAtom = atomWithLazy(async () => (await import("uplot")).default);
const Uplot = React.lazy(() => import("uplot-react"));

interface MetricProps {
    host: string;
    metric: MetricId;
    location: LocationSlug;
}

const ASPECT_RATIO = 1.5;
const MIN_HEIGHT = 100;
const MAX_HEIGHT = 180;

/**
 * Calculate & apply responsive dimensions to its children (suitable for a plot).
 *
 * Uses a fixed aspect ratio and limits the height.
 */
export function PlotSizer({
    children,
    ...props
}: { children?: (width: number, height: number) => ReactNode } & Omit<ComponentProps<"div">, "children" | "style">) {
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState<number>();
    const [height, setHeight] = useState<number>();

    useLayoutSizeObserver({
        ref,
        onResize(rect) {
            setWidth(rect.width);
            setHeight(clamp(Math.round(rect.width / ASPECT_RATIO), MIN_HEIGHT, MAX_HEIGHT));
        },
    });

    const hasDimensions = width != null && height != null;

    return (
        <div {...props} style={hasDimensions ? { height: `${height}px` } : undefined} ref={ref}>
            {hasDimensions ? children?.(width, height) : null}
        </div>
    );
}

export function MetricChart({ host, metric, location, ...props }: MetricProps & ComponentProps<"div">) {
    const manager = useAtomValue(metricsAtom);
    const [state, setState] = useState<State | undefined>();
    const [events, setEvents] = useState(manager.histories.get(host, metric)?.events);

    useEffect(() => {
        return manager.addListener(location, host, metric, {
            onState(state) {
                setState(state);
            },
            onMessage(_event, history) {
                // NB: Copy the array to trigger a re-render
                setEvents([...history.events]);
            },
        });
    }, [manager, host, metric, location]);

    function logData() {
        console.debug(
            "Data:\n%s",
            events?.map(({ time, value }) => `${shortTimeFormat(time)}: ${value.toPrecision(3)}`).join("\n"),
        );
    }

    return (
        // NB: Full height required for vertical centering
        <PlotSizer {...mcn("h-full flex justify-center items-center", props)} onClick={logData}>
            {(width, height) =>
                state === "suspended" ? (
                    <SuspendedMetric />
                ) : events != null ? (
                    <Suspense>
                        <MetricPlot width={width} height={height} events={events} scale={getMetricScale(metric)} />
                    </Suspense>
                ) : (
                    <NoData />
                )
            }
        </PlotSizer>
    );
}

/** Plot options.
 * @see {@link uPlot.Options}
 * @see https://leeoniya.github.io/uPlot/#documentation-wip
 */
type Opts = Partial<uPlot.Options>;

function useCommonOptions() {
    const primaryColor = useThemeVar("color-primary");
    return useMemo(
        () =>
            ({
                // Use milliseconds for X-axis
                ms: 1,

                // Hide unnecessary elements
                legend: { show: false },

                series: [
                    {},
                    {
                        stroke: primaryColor,
                        // Disable pixel snapping so points don't slightly shift on chart updates
                        pxAlign: false,
                        points: { show: false },
                    },
                ],

                cursor: {
                    y: false,
                    // Disable dragging to prevent zooming
                    drag: { setScale: false },
                },

                scales: {
                    x: getTimeXRange(),
                },
            }) as const satisfies Opts,
        [primaryColor],
    );
}

interface MetricScale {
    multiplier: number;
    unit: string;
    /** Highest value on Y-scale will be _at least_ this. */
    minYScale?: number;
}

function getMetricScale(metric: MetricId): MetricScale {
    switch (metric) {
        case "libvirt.guest_time_per_vcpu_delta":
            return { multiplier: 100, unit: "%", minYScale: 10 };
        case "libvirt.used_memory_kb":
            return { multiplier: 1 / 1024, unit: "MiB" };
        case "libvirt.block_wr_bytes_delta":
            return { multiplier: 1 / 1_000_000, unit: "MB/s" };
        case "libvirt.net_rx_bytes_delta":
        case "libvirt.net_tx_bytes_delta":
            // bytes/sec -> Mbit/sec
            return { multiplier: 8 / 1_000_000, unit: "Mbit/s" };
        default:
            exhaustiveSwitchCheck(metric);
    }
}

export function MetricPlot({
    events,
    scale,
    width,
    height,
}: {
    events: EventSummary[];
    width: number;
    height: number;
    scale: MetricScale;
}) {
    const uplot = useAtomValue(uplotImportAtom);

    const textColor = useThemeVar("color-muted");
    const lineColor = useThemeVar("color-grey-1");

    const tooltipAtom = useOnce(() => atom<TooltipData | undefined>(undefined));
    const setTooltip = useSetAtom(tooltipAtom);

    const commonOptions = useCommonOptions();

    const { multiplier, minYScale } = scale;

    // NB: All options must be memoized separately (from width and height) to prevent plot recreation since
    // `react-uplot` compares values using `Object.is` and not deep comparison.
    const baseOptions = useMemo(() => {
        const fontSize = 11;
        const axisBase: uPlot.Axis = {
            grid: { stroke: lineColor, width: 1 },
            stroke: textColor,
        };
        return mergeDeep(commonOptions, {
            // NB: Leave padding for the zero line / series / labels
            padding: [0, 0, Math.max(fontSize / 2 + 2, 2), 0],

            axes: [
                {
                    ...axisBase,
                    // Hide the axis labels
                    size: 0,
                    values: [],
                    ticks: { show: false },

                    // X-axis grid line every minute
                    space: 20,
                    incrs: [1 * MINUTES],
                },
                {
                    ...axisBase,
                    font: `${fontSize}px sans-serif`,
                    space: 45,
                    size: 50,
                    ticks: { size: 6 },
                },
            ],

            scales: {
                y: {
                    // NB: Fixed minimum at 0
                    range: (_plot, _initMin, initMax, _scaleKey) => [
                        0,
                        /* Leave 10% padding on top & minimum scale */ Math.max(initMax, minYScale ?? 1) * 1.1,
                    ],
                },
            },
            plugins: [tooltipPlugin(setTooltip)],
        } satisfies Opts);
    }, [commonOptions, lineColor, textColor, setTooltip, minYScale]);

    const options = useMemo((): uPlot.Options => ({ width, height, ...baseOptions }), [width, height, baseOptions]);

    const data = useMemo(() => makePlotData(events, multiplier), [events, multiplier]);

    // TODO: Format numbers using react-aria's NumberFormatter
    return (
        <>
            <Uplot
                options={options}
                data={data}
                onCreate={(_plot) => {
                    console.debug("Plot created (%s pts)", events.length);
                }}
            />
            <PlotTooltip
                className="bg-white color-text p-1"
                atom={tooltipAtom}
                formatValue={(value) => `${uplot.fmtNum(value)} ${scale.unit}`}
            />
        </>
    );
}

// TODO: Keep track of suspended state in history (otherwise eg. toggling the sidebar will reset the state)

interface MiniPlotProps {
    events: EventSummary[];
    width?: number;
    height?: number;
    debug?: boolean;
}

export function MiniPlot({ events, width = 72, height = 32, debug = false }: MiniPlotProps) {
    const commonOptions = useCommonOptions();

    const options = useMemo(
        (): uPlot.Options =>
            mergeDeep(commonOptions, {
                width,
                height,

                // Hide unnecessary elements
                cursor: { show: debug },
                axes: [{ show: debug }, { show: debug }],

                scales: {
                    y: { range: [0, 1] },
                },
            } satisfies Opts),
        [width, height, debug, commonOptions],
    );
    const data = useMemo(() => makePlotData(events), [events]);
    return (
        // Render nothing while lazy loading the component
        <Suspense>
            <Uplot options={options} data={data} />
        </Suspense>
    );
}

interface MiniMetricChartProps extends MetricProps, Omit<MiniPlotProps, "events"> {}

export function MiniMetricChart({ host, metric, location, ...plotProps }: MiniMetricChartProps) {
    const manager = useAtomValue(metricsAtom);
    const [state, setState] = useState<State | undefined>();
    const [events, setEvents] = useState(manager.histories.get(host, metric)?.events);

    useEffect(() => {
        return manager.addListener(location, host, metric, {
            onState(state) {
                setState(state);
            },
            onMessage(_event, history) {
                // NB: Copy the array to trigger a re-render
                setEvents([...history.events]);
            },
        });
    }, [manager, host, metric, location]);

    return state === "suspended" ? (
        <SuspendedMetric />
    ) : events != null ? (
        <MiniPlot events={events} {...plotProps} />
    ) : (
        <NoData />
    );
}

function makePlotData(events: EventSummary[], multiplier = 1): uPlot.AlignedData {
    return [
        // X-axis
        events.map(({ time }) => time.getTime()),
        // Y-axis
        // NB: Forbids negative values
        events.map(({ value }) => Math.max(0, value) * multiplier),
    ];
}

/** Force minimum and maximum time range */
function getTimeXRange(minTimeRangeSec = 1 * MINUTES, maxTimeRangeSec = 5 * MINUTES): uPlot.Scale {
    return {
        range: (_self, initMin, initMax, _scaleKey) => [
            clamp(initMin, initMax - maxTimeRangeSec, initMax - minTimeRangeSec),
            initMax,
        ],
    };
}

function SuspendedMetric() {
    return <InfoTooltip text="Metrics for this resource are not loaded for performance reasons">Running</InfoTooltip>;
}

function NoData() {
    return "No data";
}
